<template>
  <div id="widgetable-wrapper">
    <Header />
    <main>
      <ExampleImage />
      <DownloadButtons />
    </main>
    <Footer />
  </div>
</template>

<script>
import Header from './components/Header.vue'
import DownloadButtons from './components/DownloadButtons.vue'
import ExampleImage from './components/ExampleImage.vue'
import Footer from './components/Footer.vue'

export default {
  name: 'App',
  components: {
    Header,
    DownloadButtons,
    ExampleImage,
    Footer
  }
}
</script>

<style>
#widgetable-wrapper {
  width: 100vw;
  min-height: 100vh;
  color: #fff;
  box-sizing: border-box;
}

@media screen and (max-width: 900px) {
  #widgetable-wrapper {
    background: url('./assets/bg-h5.jpeg') no-repeat center/cover;
    padding-top: 2em;
  }
}

@media screen and (orientation: landscape) and (min-width: 900px) {
  #widgetable-wrapper {
    min-width: 980px;
    background: url('./assets/bg-pc.jpeg') no-repeat center/cover;
    position: relative;
  }
}

/* Add these global styles */
body {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>